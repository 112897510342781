import React from 'react';
import { TextField, TextFieldProps } from '@mui/material'

const CustomTextField = (props: TextFieldProps) => {
  return (
    <TextField
      fullWidth
      required
      variant='outlined'
      margin='normal'
      size={props.size}
      placeholder={props.placeholder}
      label={props.label}
      type={props.type}
      value={props.value}
      onChange={props.onChange}
      inputProps={props.inputProps}
      multiline={props.multiline}
      rows={props.rows}
    />
  );
};

export default CustomTextField;