import { toast } from "react-toastify";

export async function sendSMS(name: string, contactNumber: string) {
  const accountId = process.env.REACT_APP_TWILIO_ACCOUNT_ID
  const authToken = process.env.REACT_APP_TWILIO_AUTH_TOKEN

  const message = `Hi ${name}, Thank you for your purchase from Sling Mobility. Please download the sling mobile app from this link.\nPlayStore:https://play.google.com/store/apps/details?id=com.slingmobilityrider.mobile\n`;

  await fetch(`https://api.twilio.com/2010-04-01/Accounts/${accountId}/Messages.json`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(`${accountId}:${authToken}`)}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      From: process.env.REACT_APP_TWILIO_PHONE_NO as string,
      To: contactNumber,
      Body: message,
    }).toString(),
  }).then(response => {
    toast.success("Mobile app download link SMS sent")
  }).catch(error => {
    toast.error("Failed to send mobile app download link");
  });
}