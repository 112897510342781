import React, { useEffect, useState } from 'react'
import FileUpload from '../../../components/FileUpload/FileUpload'
import { CircularProgress } from '@mui/material';
import SelectableCard from '../../../components/SelectableCard/SelectableCard';
import { PrimaryButton, SecondaryButton } from '../../../components/CustomMUI/CustomButtons';
import { toast } from 'react-toastify';

function Leasing(props: { agents: any, isLoading: boolean, handleBack: CallableFunction; handleNext: CallableFunction; }) {
  const [userNICFront, setUserNICFront] = useState("" as any);
  const [userNICBack, setUserNICBack] = useState("" as any);
  const [guarantorNICFront, setGuarantorNICFront] = useState("" as any);
  const [guarantorNICBack, setGuarantorNICBack] = useState("" as any);
  const [selectedAgent, setSelectedAgent] = useState("" as string)

  useEffect(() => {
    if (localStorage.getItem("PaymentInformation")) {
      let PaymentInformation = JSON.parse(localStorage.getItem("PaymentInformation") as string)
      setSelectedAgent(PaymentInformation.selectedAgent as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleProceed() {
    if (selectedAgent === "") {
      return toast.error("Please select a agent")
    }
    if (userNICFront === "" || userNICBack === "" || guarantorNICFront === "" || guarantorNICBack === "") {
      return toast.error("Please upload all the images")
    }
    const PaymentInformation = {
      paymentType: "Leasing",
      selectedAgent,
      userNICFront,
      userNICBack,
      guarantorNICFront,
      guarantorNICBack,
    }
    localStorage.setItem("PaymentInformation", JSON.stringify(PaymentInformation))
    props.handleNext()
  }

  return (
    <div className='container text-center'>
      {props.isLoading ?
        <CircularProgress />
        :
        <div>
          <div className='select-card-grid mb-4'>
            {props.agents?.map((agent: any, index: any) =>
              <SelectableCard
                cardData={agent}
                selectedId={selectedAgent}
                key={index}
                onClick={setSelectedAgent}
              />
            )}
          </div>
          <div className='row'>
            <div className='col-xl-6'>
              <FileUpload
                heading="Upload your NIC front side"
                onChange={(value: any) => setUserNICFront(value)}
              />
            </div>
            <div className='col-xl-6'>
              <FileUpload
                heading="Upload your NIC back side"
                onChange={(value: any) => setUserNICBack(value)}
              />
            </div>
            <div className='col-xl-6'>
              <FileUpload
                heading="Upload guarantor's NIC front side"
                onChange={(value: any) => setGuarantorNICFront(value)}
              />
            </div>
            <div className='col-xl-6'>
              <FileUpload
                heading="Upload guarantor's NIC back side"
                onChange={(value: any) => setGuarantorNICBack(value)}
              />
            </div>
          </div>
          <div className='d-flex justify-content-start'>
            <PrimaryButton onClick={handleProceed} className='me-3'> Next </PrimaryButton>
            <SecondaryButton onClick={() => props.handleBack()}> Back </SecondaryButton>
          </div>
        </div>
      }
    </div>
  )
}

export default Leasing