import React, { useEffect, useState } from 'react'
import FirebaseServices from '../../Services/FirebaseServices';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import SelectableCard from '../../components/SelectableCard/SelectableCard';
import { PrimaryButton } from '../../components/CustomMUI/CustomButtons';

function Vehicles(props: { handleNext: CallableFunction }) {
  const firebase = new FirebaseServices();
  const [bikes, setBikes] = useState([] as any)
  const [selectedBike, setSelectedBike] = useState("" as string)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getBikes()

    if (localStorage.getItem("SelectedBike")) {
      setSelectedBike(localStorage.getItem("SelectedBike") as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getBikes() {
    setIsLoading(true)
    await firebase.getAllBikes().then((bikeDetails: any) => {
      setBikes(bikeDetails)
      setIsLoading(false)
    }).catch((error) => {
      toast.error("Failed to fetch bikes")
    });
  }

  function handleProceed() {
    if (selectedBike === "") {
      return toast.error("Please select the vehicle")
    }
    
    localStorage.setItem("SelectedBike", selectedBike)
    props.handleNext()
  }

  return (
    <div className='container mt-3'>
      {isLoading ?
        <CircularProgress />
        :
        <div>
          <div className='select-card-grid mb-5'>
            {bikes?.map((bike: any, index: any) =>
              <SelectableCard
                cardData={bike}
                selectedId={selectedBike}
                key={index}
                onClick={setSelectedBike}
              />
            )}
          </div>
          <PrimaryButton onClick={handleProceed}> Next </PrimaryButton>
        </div>
      }
    </div>
  )
}

export default Vehicles