import React from 'react'
import { CheckCircle } from '@mui/icons-material'
import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material'
import './SelectableCard.css'

function SelectableCard(props: { cardData: any, selectedId: string, onClick: CallableFunction }) {
  return (
    <div>
      <Card
        className={props.selectedId === props.cardData.id ? "card card-select active" : "card card-select"}
        onClick={() => props.onClick(props.cardData.id)}
      >
        <CardActionArea className='p-2'>
          {props.selectedId === props.cardData.id ? (
            <div className='text-end'>
              <CheckCircle color='success' />
            </div>
          ) : <span>&nbsp;</span>}
          <CardMedia>
            {props.cardData.logo ? (
              <img src={props.cardData.logo} className='img-fluid' width="150px" alt='logo' />
            ) : props.cardData.images ? (
              <img src={props.cardData.images[0]} className='img-fluid bike-img' alt='bike' />
            ) : props.cardData.extra ? (
              <img src='/images/Battery.png' className='img-fluid' width="78px" alt='battery' />
            ) : props.cardData.name ? (
              <img src={props.cardData.partnerLogo} className='img-fluid partner-img' alt='logo' />
            ) : null}
          </CardMedia>
          <CardContent className='px-3 py-1'>
            {props.cardData.model ? (
              <div>
                <h4>Sling {props.cardData.model}</h4>
                {/* <p className='mb-0'>{props.cardData.price.toLocaleString()} LKR</p> */}
              </div>
            ) : props.cardData.extra ? (
              <div>
                <h5>{props.cardData.range} km</h5>
                <p className='mb-0'>{props.cardData.price.toLocaleString()}USD</p>
              </div>
            ) : (
              <div>
                <h5>{props.cardData.name}</h5>
              </div>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  )
}

export default SelectableCard