import React, { useEffect, useState } from 'react'
import Leasing from './Leasing';
import FullPayment from './FullPayment';
import { Button } from '@mui/material';
import FirebaseServices from '../../../Services/FirebaseServices';
import { toast } from 'react-toastify';

function Payments(props: { handleBack: CallableFunction; handleNext: CallableFunction; }) {
  const firebase = new FirebaseServices();
  const [isLoading, setIsLoading] = useState(false)
  const [agents, setAgent] = useState([] as any)
  const [tabValue, setTabValue] = useState("Full Payment");

  useEffect(() => {
    getAllLeasingAgents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getAllLeasingAgents() {
    setIsLoading(true)
    await firebase.getAllLeasingAgents().then((agentDetails: any) => {
      setAgent(agentDetails)
      setIsLoading(false)
    }).catch((error) => {
      toast.error(error)
    });
  }

  return (
    <div className='container'>
      <div className="btn-tabs">
        <Button
          variant='contained'
          className={tabValue === "Full Payment" ? "active" : "inactive"}
          onClick={() => setTabValue("Full Payment")}
        >
          Full Payment
        </Button>
        <Button
          variant='contained'
          className={tabValue === "Leasing" ? "active" : "inactive"}
          onClick={() => setTabValue("Leasing")}
        >
          Leasing
        </Button>
      </div>
      <div className="pt-5">
        {tabValue === "Leasing" ? (
          <Leasing
            isLoading={isLoading}
            agents={agents}
            handleBack={() => props.handleBack()}
            handleNext={() => props.handleNext()}
          />
        ) : (
          <FullPayment
            handleBack={() => props.handleBack()}
            handleNext={() => props.handleNext()}
          />
        )}
      </div>
    </div>
  )
}

export default Payments