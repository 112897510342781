import React, { useEffect, useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../../components/CustomMUI/CustomButtons'
import CustomTextField from '../../components/CustomMUI/CustomTextField'
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material'
import firebase from '../../Services/FirebaseConfig';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

function KYC(props: { handleNext: CallableFunction, handleBack: CallableFunction }) {
  const [firstname, setFirstname] = useState("" as string)
  const [lastname, setLastname] = useState("" as string)
  const [email, setEmail] = useState("" as string)
  const [contactNumber, setContactNumber] = useState("" as string)
  const [nic, setNic] = useState("" as string)
  const [isLoading, setIsLoading] = useState(false as boolean)

  useEffect(() => {
    if (localStorage.getItem("CustomerDetails")) {
      let CustomerDetails = JSON.parse(localStorage.getItem("CustomerDetails") as string)
      setFirstname(CustomerDetails.firstname)
      setLastname(CustomerDetails.lastname)
      setEmail(CustomerDetails.email)
      setContactNumber(CustomerDetails.contactNumber)
      setNic(CustomerDetails.nic)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function handleSubmit(event: { preventDefault: () => void }) {
    event.preventDefault()
    setIsLoading(true)
    const checkCustomerEmail = await firebase.app().firestore().collection("users").where("email", "==", email).get()
    if (checkCustomerEmail.docs.length > 0) {
      toast.error("Email is already taken")
      setIsLoading(false)
      return
    }

    const checkCustomerNIC = await firebase.app().firestore().collection("users").where("nic", "==", nic).get()
    if (checkCustomerNIC.docs.length > 0) {
      toast.error("NIC is already taken")
      setIsLoading(false)
      return
    }

    const checkCustomerPhone = await firebase.app().firestore().collection("users").where("contactNumber", "==", nic).get()
    if (checkCustomerPhone.docs.length > 0) {
      toast.error("Contact number is already taken")
      setIsLoading(false)
      return
    }

    const CustomerDetails = {
      firstname,
      lastname,
      email,
      contactNumber,
      nic
    }

    localStorage.setItem("CustomerDetails", JSON.stringify(CustomerDetails))
    setIsLoading(false)
    props.handleNext()
  }

  return (
    <form className='container mt-3' onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-xl-6'>
          <CustomTextField label='Firstname' type='name' value={firstname} onChange={(e) => setFirstname(e.target.value)} />
        </div>
        <div className='col-xl-6'>
          <CustomTextField label='Lastname' type='name' value={lastname} onChange={(e) => setLastname(e.target.value)} />
        </div>
        <div className='col-xl-6'>
          <CustomTextField
            label='Contact number' type='text' value={contactNumber} onChange={(e) => setContactNumber(e.target.value)}
            inputProps={{ pattern: "([+]{1}[9]{1}[4]{1}[0-9]{2}[0-9]{7})" }}
          />
        </div>
        <div className='col-xl-6'>
          <CustomTextField label='Email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className='col-xl-6'>
          <CustomTextField
            label='NIC' type='text' value={nic} onChange={(e) => setNic(e.target.value)}
            inputProps={{ pattern: "([0-9]{9}[x|X|v|V]|[0-9]{12})" }}
          />
        </div>
      </div>
      <div className='d-flex align-items-center'>
        <FormControlLabel required control={<Checkbox required />} label="I agree to terms and conditions." />
        <Link to='/terms'><u>Read terms & conditions.</u></Link>
      </div>

      <div className='d-flex justify-content-start mt-3'>
        <PrimaryButton type='submit' className='me-3'>
          {isLoading ? <CircularProgress color='inherit' size={24} /> : "Next"}</PrimaryButton>
        <SecondaryButton onClick={() => props.handleBack()}> Back </SecondaryButton>
      </div>
    </form>
  )
}

export default KYC