import { useState } from "react";
import "./FileUpload.css";
import { Button } from "@mui/material";
import { CameraAltOutlined } from "@mui/icons-material";

function FileUpload(props: { heading: string; onChange: CallableFunction }) {
  const [imgUrl, setImgUrl] = useState("");

  function toBase64(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <div className="file-upload-container" >
      {imgUrl && <img className="img-fluid mb-4" src={imgUrl} alt="preview" />}
      <h5>{props.heading}</h5>
      <p>Accepts only JPEG, PNG, PDF formats</p>
      <input
        type="file"
        id={props.heading}
        accept=".pdf, image/png, image/jpeg, image/* "
        required
        onChange={(e) => {
          if (e.target.files) {
            toBase64(e.target.files[0]).then((url: any) => {
              setImgUrl(url);
              props.onChange(url);
            });
          }
        }}
      />
      <label htmlFor={props.heading}>
        <Button variant="outlined" className="primary-hollow-btn" component="span">
          <CameraAltOutlined /> &nbsp; Select Image
        </Button>
      </label>
    </div>
  );
}


export default FileUpload;

