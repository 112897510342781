import React from 'react'
import './Home.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Ecosystem from './Ecosystem';

function Hero() {
  return (
    <div className='hero py-2'>
      <div className='w-100'>
        <div className='d-flex justify-content-between'>
          <div className='px-5 d-flex flex-wrap'>
            <h1 className='display-1 fw-bolder mb-0' data-aos-anchor="hero" data-aos="fade-up">Most&nbsp;</h1>
            <Carousel
              infiniteLoop
              autoPlay
              axis='vertical'
              showIndicators={false}
              showArrows={false}
              showThumbs={false}
              showStatus={false}
            >
              <h1 className='display-1 fw-bolder mb-0 text-pastel-green'>Innovative&nbsp;</h1>
              <h1 className='display-1 fw-bolder mb-0 text-light-blue'>Affordable&nbsp;</h1>
            </Carousel>
            <h1
              className='display-1 fw-bolder mb-0'
              data-aos="fade-up" aos-delay="400" data-aos-anchor="hero"
            >
              EV Solution&nbsp;
            </h1>
          </div>
          <Ecosystem/>
        </div>
      </div>
    </div>
  )
}

export default Hero