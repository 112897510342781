import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

function Gallery(){
  const itemData = [
    {
      img: '/images/gallery/slingarticle2.jpeg',
      title: 'Screenshot 1',
      cols: 3,
    },
    {
      img: '/images/gallery/slingarticle1.jpeg',
      title: 'Sling Article 1',
      cols: 1,
    },
    {
      img: '/images/gallery/slingarticle3.jpeg',
      title: 'Sling Article 3',
      rows: 1,
      cols: 1,
    },
    {
      img: '/images/gallery/slingarticle4.jpg',
      title: 'Sling Article 4',
      cols: 4,
    },
  ];

  return (
    <div className='container text-center my-5'>
      <h1 className='py-5 page-title'>Gallery</h1>
      <ImageList
        variant="quilted"
        cols={4}
        gap={8}
        rowHeight={'auto'}
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
            <img
              {...srcset(item.img, 450, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export default Gallery;
