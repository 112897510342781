import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './Bike.css'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TestRide from '../../components/TestRide/TestRide';
import { toast } from 'react-toastify';
import FirebaseServices from '../../Services/FirebaseServices';
import { PrimaryButton, SecondaryButton } from '../../components/CustomMUI/CustomButtons';

function Bike() {
  const firebase = new FirebaseServices();
  const [showModal, setShowModal] = useState(false)
  const [bike, setBike] = useState(null as any)
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams() as any
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };

  useEffect(() => {
    getBikeById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function getBikeById() {
    setIsLoading(true)
    await firebase.getBikeById(id).then((bikeDetails: any) => {
      setBike(bikeDetails)
      setIsLoading(false)
    }).catch((error) => {
      toast.error(error)
    });
  }

  async function handleSelect(bikeId: string) {
    localStorage.setItem("SelectedBike", bikeId)
    navigate("/buy")
  }

  return (
    <>
      <div className='container'>
        {isLoading ?
          <div className='text-center vh-100'>
            <CircularProgress />
          </div>
          :
          <div>
            <div className='vh-100 d-flex justify-content-between flex-column'>
              <Carousel className='h-100 my-5' responsive={responsive} renderArrowsWhenDisabled={true} showDots={true}>
                {bike?.images ? (
                  bike.images.map((image: any, index: any) =>
                    <div className='d-flex justify-content-center h-100 w-100'>
                      <img key={index} src={image} className='img-fluid carousel-img' alt="scooter" />
                    </div>
                  )
                ) : (
                  <></>
                )}
              </Carousel>
              <div className='row align-items-center justify-content-between mb-5'>
                <div className='col-xl-6 bike-model'>
                  <p>Sling {bike?.model}</p>
                  {/* <p>{bike?.price.toLocaleString()}LKR</p> */}
                </div>
                <div className='col-xl-4 d-flex justify-content-around'>
                  <SecondaryButton onClick={() => setShowModal(true)} >
                    Book test ride
                  </SecondaryButton>
                  <PrimaryButton onClick={() => handleSelect(bike?.id)}>
                    Buy now
                  </PrimaryButton>
                </div>
              </div>
            </div>
            <div className='row justify-content-between'>
              <div className='col-xl-5'>
                <div className='mb-5'>
                  <p className='section'>Performance</p>
                  <hr />
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h6>Motor power</h6>
                      <h3>{bike?.power.toFixed(1)}kW</h3>
                    </div>
                    <div className='col-6'>
                      <h6>Top speed</h6>
                      <h3>{bike?.topSpeed}Kmph</h3>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h6>Motor type</h6>
                      <h3>{bike?.motorType}</h3>
                    </div>
                    <div className='col-6'>
                      <h6>Transmission</h6>
                      <h3>{bike?.gear}</h3>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h6>Climbing</h6>
                      <h3>{bike?.climbing}%</h3>
                    </div>
                    <div className='col-6'>
                      <h6>Water & dust resistance (VIM)</h6>
                      <h3>IP66</h3>
                    </div>
                  </div>
                </div>
                <div className='mb-5'>
                  <p className='section'>Peripheral & Featured</p>
                  <hr />
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h6>Headlight, Indicator</h6>
                      <h3>LED</h3>
                    </div>
                    <div className='col-6'>
                      <h6>Power Outputs</h6>
                      <h3>USB</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-5'>
                <div className='mb-5'>
                  <p className='section'>Range</p>
                  <hr className='section-hr' />
                  <div className='mb-3'>
                    <h6>True range</h6>
                    <h3>{bike?.range} km</h3>
                  </div>
                </div>
                <div className='mb-5'>
                  <p className='section'>Wheels & Brakes</p>
                  <hr />
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h6>Brake Type</h6>
                      <h3>{bike?.brakes}</h3>
                    </div>
                    <div className='col-6'>
                      <h6>Tyre</h6>
                      <h4>{bike?.tyre}</h4>
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-6'>
                      <h6>Wheel Type</h6>
                      <h3>Alloy Wheels</h3>
                    </div>
                  </div>
                </div>
                <div className='mb-5'>
                  <p className='section'>Fork & Suspension</p>
                  <hr className='section-hr' />
                  <div>
                    <h6>Rear suspension</h6>
                    <h3>4-point adjustment</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <Dialog open={showModal} disableRestoreFocus fullWidth onClose={() => setShowModal(false)} >
        <DialogTitle className='text-center my-1'><h3>Book a test drive</h3></DialogTitle>
        <DialogContent>
          <TestRide onClose={() => setShowModal(false)} bikeId={id} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Bike