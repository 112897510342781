import React from 'react'
import { PrimaryButton } from '../../components/CustomMUI/CustomButtons'
import { useNavigate } from 'react-router-dom'

function Services() {
  const navigate = useNavigate()

  return (
    <div className='container p-5 text-center'>
      <h1 className='page-title'>Our Services</h1>
      <p>Solutions We Provide</p>
      <div className='mt-5 row'>
        <div className='col-xl-6'>
          <div className='mb-5 card' data-aos="fade-right" >
            <div className='row align-items-center' style={{ minHeight: '220px' }}>
              <div className='col-xl-6'>
                <img src='/images/fleet.png' className='img-fluid' width={'300px'} alt='fleet' />
              </div>
              <div className='col-xl-6 text-center'>
                <h3 className='mb-4'>Fleet Management System</h3>
                <PrimaryButton onClick={() => navigate('/fleet')}>
                  Learn More
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-6'>
          <div className='mb-5 card bg-light' data-aos="fade-left">
            <div className='row align-items-center' style={{ minHeight: '220px' }}>
              <div className='col-xl-6'>
                <img src='/images/Uber.jpg' className='img-fluid' width={'300px'} alt='fleet' />
              </div>
              <div className='col-xl-6 text-center text-dark'>
                <h3 className='mb-4'>Ride & Earn</h3>
                <p>Make an extra income while riding your bike</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services