import React, { useState } from 'react'
import { PrimaryButton, SecondaryButton } from '../components/CustomMUI/CustomButtons';
import { paymentGateway } from '../Services/HelperFunctionsJS';
import CustomTextField from '../components/CustomMUI/CustomTextField';

function PaymentTest(props: { handleBack: CallableFunction; handleNext: CallableFunction; }) {
  const customerDetails = JSON.parse(localStorage.getItem("CustomerDetails") as string)
  const address = "84, Dharmapala Mawatha,"
  const city = "Kollupitiya"

  const [invoiceNo, setInvoiceNo] = useState('')

  async function handlePayment(e: { preventDefault: () => void; }) {
    e.preventDefault()
    props.handleNext();
    localStorage.setItem("InvoiceNo", invoiceNo)
    paymentGateway(customerDetails.firstname, customerDetails.lastname, customerDetails.contactNumber, customerDetails.email, 1000.00, invoiceNo, address, city)
  }

  return (<div>
    <form className='container text-center my-5 py-5'>
      <CustomTextField label='Invoice number' type='text' value={invoiceNo} onChange={(e) => setInvoiceNo(e.target.value)} />
    </form>
    <div className='d-flex justify-content-start'>
        <PrimaryButton onClick={handlePayment} className='me-3'> Pay now </PrimaryButton>
        <SecondaryButton onClick={() => props.handleBack()}> Back </SecondaryButton>
      </div>
  </div>
    
  );
}

export default PaymentTest