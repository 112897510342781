import PaymentTest from '../../PaymentTest';

function FullPayment(props: { handleBack: CallableFunction; handleNext: CallableFunction; }) {

  function handleProceed() {
    const PaymentInformation = {
      paymentType: "Full payment"
    }
    localStorage.setItem("PaymentInformation", JSON.stringify(PaymentInformation))
  }

  return (
    <div className='container'>
      <PaymentTest  handleBack={() => props.handleBack()}
            handleNext={handleProceed} />
    </div>
  )
}

export default FullPayment