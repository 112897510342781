import React, { useEffect, useState } from "react";
import firebase from "../Services/FirebaseConfig";
import "./Invoice.css";
import { PrimaryButton } from "../components/CustomMUI/CustomButtons";
import { useNavigate } from "react-router-dom";

function PaymentReturn() {
  const [paymentData, setPaymentData] = useState(null as any);
  const navigate = useNavigate();

  useEffect(() => {
    const invoiceNo = localStorage.getItem("InvoiceNo");
    getPaymentData(invoiceNo as string);
  }, []);

  async function getPaymentData(invoiceNo: string) {
    const data = await firebase
      .app()
      .firestore()
      .collection("payments")
      .where("invoiceNo", "==", invoiceNo)
      .get();
    if (data.empty) {
      console.log("No matching documents.");
      return;
    }
    setPaymentData(data.docs[0].data());
    const doc = data.docs[0];
    localStorage.setItem("PaymentDocId", doc.id);
  }

  function handleProceed() {
    navigate("/buy/4");
  }

  return (
    <div className="container text-center my-5 py-5">
      {(paymentData && paymentData.statusMessage) === "SUCCESS" ? (
        <div>
          <h1>Payment Successful</h1>
          <div>
            <div className="invoice">
              <h1>Invoice</h1>
              <div className="invoice-item">
                <span className="label">Card Holder Name:</span>
                <span>{paymentData.cardHolderName}</span>
              </div>
              <div className="invoice-item">
                <span className="label">Card Number:</span>
                <span>{paymentData.cardNumber}</span>
              </div>
              <div className="invoice-item">
                <span className="label">Invoice No:</span>
                <span>{paymentData.invoiceNo}</span>
              </div>
              <div className="invoice-item">
                <span className="label">Payable Amount:</span>
                <span>{paymentData.payableAmount}</span>
              </div>
              <div className="invoice-item">
                <span className="label">Payable Currency:</span>
                <span>{paymentData.payableCurrency}</span>
              </div>
              <div className="invoice-item">
                <span className="label">Payment ID:</span>
                <span>{paymentData.paymentId}</span>
              </div>
              <div className="invoice-item">
                <span className="label">Payment Scheme:</span>
                <span>{paymentData.paymentScheme}</span>
              </div>
              <div className="invoice-item">
                <span className="label">Status Message:</span>
                <span>{paymentData.statusMessage}</span>
              </div>
            </div>
            <div className="justify-content-start pt-5">
              <PrimaryButton onClick={handleProceed} className="me-3">
                {" "}
                Continue{" "}
              </PrimaryButton>
            </div>
          </div>
        </div>
      ) : paymentData ? (
        <h1>Payment Failed</h1>
      ) : (
        <h1>No data found</h1>
      )}
    </div>
  );
}

export default PaymentReturn;
