import { sha512 } from "js-sha512";
import { payablePayment } from 'payable-ipg-js';

async function calculatePaymentHash(merchantKey, invoiceId, amount, currencyCode) {
  const merchantTokenHash = await sha512(process.env.REACT_APP_MERCHANT_TOKEN).toUpperCase();

  const hash = await sha512(`${merchantKey}|${invoiceId}|${amount}|${currencyCode}|${merchantTokenHash}`).toUpperCase();
  return hash;
}

export async function paymentGateway(firstname, lastname, phone, email, amount, paymentId, address, city) {
  const payment = {
    notifyUrl: "https://us-central1-sling-mobility-d5949.cloudfunctions.net/capturePaymentCallback",
    logoUrl: "https://slingmobility.com/images/LogoVertical.png",
    returnUrl: "https://slingmobility.com/payment-return",
    checkValue: await calculatePaymentHash(process.env.REACT_APP_MERCHANT_KEY, paymentId, amount.toFixed(2), "LKR"),
    orderDescription: "Bike full payment",
    invoiceId: paymentId,
    merchantKey: process.env.REACT_APP_MERCHANT_KEY,
    customerFirstName: firstname,
    customerLastName: lastname,
    customerMobilePhone: phone,
    customerEmail: email,
    billingAddressStreet: address,
    billingAddressCity: city,
    billingAddressCountry: "LKA",
    amount: amount.toFixed(2),
    currencyCode: "LKR",
    paymentType: "1"
  };

  try {
    await payablePayment(payment, true);
  } catch (error) {
    console.log(error);
  }
}