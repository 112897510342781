import React, { useEffect, useState, useMemo } from "react";
//import { useNavigate } from "react-router-dom";
import "./carbonQr.css";
import { ReactComponent as CO2Banner } from "./CO2 Banner.svg";
import { ReactComponent as LeafIcon } from "./Leaf.svg";
import { ReactComponent as ArrowIcon } from "./Arrow.svg";
import { ReactComponent as Facebook } from "./Facebook.svg";
import { ReactComponent as Instagram } from "./Instagram.svg";
import { ReactComponent as LinkedIn } from "./LinkedIn.svg";
import FirebaseServices from "../../Services/FirebaseServices";

function CarbonQr() {
  // Memoizing firebase to avoid unnecessary re-creation on every render
  const firebase = useMemo(() => new FirebaseServices(), []);
  const [displayedValue, setDisplayedValue] = useState<number>(0);
  const [zoomEffect, setZoomEffect] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.pathname === "/marketingQr") {
      firebase
        .updateQRCount()
        .then(() => {
          console.log("Updated QR count successfully");
        })
        .catch((error) => {
          console.error("Failed to update QR count", error);
        });
    }
  }, [firebase]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const totalSaved: number = await firebase.getTotalCarbonEmissionSaved();
        animateCounter(1, totalSaved, 1500);
        console.log("Total Carbon Emission Saved:", totalSaved);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [firebase]);

  const animateCounter = (start: number, end: number, duration: number) => {
    let startTimestamp: number | null = null;
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setDisplayedValue(Math.floor(progress * (end - start) + start));

      if (progress < 1) {
        window.requestAnimationFrame(step);
      } else {
        setZoomEffect(true);
      }
    };

    window.requestAnimationFrame(step);
  };

  const handleShareClick = () => {
    const url = window.location.href;

    if (navigator.share) {
      navigator
        .share({
          title: "Check out this page",
          text: "Here's something interesting I found:",
          url: url,
        })
        .then(() => {})
        .catch((err) => {});
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          alert("Link copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy:", err);
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = url;
      textArea.style.position = "fixed";
      textArea.style.opacity = "0";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        const msg = successful ? "Link copied to clipboard!" : "Unable to copy";
        alert(msg);
      } catch (err) {
        alert("Failed to copy the link.");
      }

      document.body.removeChild(textArea);
    }
  };

  return (
    <>
      <div className="main-gradient">
        <header className="header">
          <div className="logo">
            <img
              src="/images/logo/Sling Logo color.png"
              alt="Logo"
              className="logo-img"
            />
          </div>
          <button className="share-button" onClick={handleShareClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#1c3d7e"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="share-icon"
              width="20"
              height="20"
            >
              <circle cx="18" cy="5" r="3"></circle>
              <circle cx="6" cy="12" r="3"></circle>
              <circle cx="18" cy="19" r="3"></circle>
              <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
              <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
            </svg>
            Share
          </button>
        </header>
        <div className="MainHeading">Congratulations!</div>
        <div className="subHeading">Together we prevented</div>

        <div className={`carbon-section ${zoomEffect ? "zoom-in-out" : ""}`}>
          {displayedValue.toFixed(2)}
        </div>
        <div className="carbon-text">
          Kg of CO<sub>2</sub>e
        </div>

        <div className="carbonImage">
          <CO2Banner className="carbonImage-svg" />
        </div>
      </div>
      <body>
        <div className="support-text">
          <div>
            <span className="SubTextSection">Thank you for supporting</span>
          </div>
          <div>
            <span className="gogreenSection">"Go Green, Go Digital"</span>
            <span className="conceptText">
              concept
              <LeafIcon className="leaf-icon" />
            </span>
          </div>
        </div>

        <div className="textSection">
          Your deliveries help prevent an average of 0.56kg of carbon emissions
          per km, from polluting the air.
        </div>
        <a
          href="https://slingmobility.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="slingMobility-link"
        >
          <div className="slingMobility">
            Visit Sling Mobility
            <ArrowIcon className="arrow-icon" />
          </div>
        </a>

        <div className="Footer">
          <hr className="divider" />
          <div className="socialText">Follow us on social media</div>
          <div className="socialsub">
            Stay connected with us and get the latest updates
          </div>
          <div className="socials">
            <a
              href="https://www.facebook.com/slingmobility?mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook className="social-icon" />
            </a>
            <a
              href="https://www.instagram.com/slingmobility/?igsh=MTdvOHA5a3Jjb2p4ZA%3D%3D"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram className="social-icon" />
            </a>
            <a
              href="https://www.linkedin.com/company/slingmobility/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedIn className="social-icon" />
            </a>
          </div>
        </div>
      </body>
    </>
  );
}

export default CarbonQr;
