// Press.tsx

import React from 'react';
import { PrimaryButton } from '../../components/CustomMUI/CustomButtons';
import './Press.css';

interface NewsArticle {
  title: string;
  thumbnail: string;
  content: React.ReactNode;
  url: string;
}

const Press = () => {

  const newsArticles: NewsArticle[] = [
    {
      title: 'USAID-funded Sri Lanka Energy Program wins Innovation Award',
      thumbnail: '/images/USAID-1.jpg',
      content: "The U.S. Embassy has announced that Sri Lanka's USAID-funded Sri Lanka Energy Project (SLEP) has received top honours at the 2024 annual conference for the Society for International Development, the premier U.S. event for international development professionals.",
      url: 'https://www.newswire.lk/2024/06/14/usaid-funded-sri-lanka-energy-program-wins-innovation-award/',
    },
    {
      title: 'USAID-funded Sri Lanka Energy Program wins Innovation Award',
      thumbnail: '/images/US-AMBO.jpg',
      content: "U.S. Ambassador Julie Chung witnesses the innovative solar-powered battery swapping station at the Centre for Women's Research in Colombo. The station, part of the award-winning USAID-supported Sri Lanka Energy Project, not only promotes sustainable urban mobility but also provides a crucial income stream for the centre, empowering local women through research and community programmes. ",
      url: 'https://island.lk/usaid-funded-sri-lanka-energy-programme-wins-innovation-award-at-2024-society-for-international-development-sid-us-annual-conference/',
    },
    {
      title: 'USAID-funded Sri Lanka Energy Program wins Innovation Award',
      thumbnail: '/images/sling-sri-lanka-usaid-scaled.jpeg',
      content: "US Ambassador Julie Chung witnesses the innovative solar-powered battery swapping station at the Center for Women's Research in Colombo. Minister Kanchana Wijesekera was also present at the event.",
      url: 'https://economynext.com/sri-lankas-sling-solar-powered-ev-battery-project-wins-us-award-167990/',
    },
    {
      title: 'Sling Mobility redefines retail logistics powered by electric mobility',
      thumbnail: '/images/press_download.png',
      content: 'In line with global energy transition trends, the newly launched 360 degree retail logistics platform Sling Mobility will power the zero-emission mobility future in Sri Lanka.Facilitating a fast forward transition to sustainable transportation, Sling Mobility provides an economically efficient and environmentally friendly solution for independentcouriers ',
      url: 'https://www.dailymirror.lk/other/Sling-Mobility-redefines-retail-logistics-powered-by-electric-mobility/117-223744',
    },
    {
      title: 'SLING Mobility, USAID collaborate to promote clean energy',
      thumbnail: '/images/image.jpeg',
      content: 'This groundbreaking project will establish an operational framework for an electric vehicle (EV) ecosystem, with an emphasis on utilising solar power as a main energy source. The pilot project introduces two solar-powered battery-swapping stations strategically located at CENWOR and Fusion Sarvodaya in Colombo',
      url: 'https://www.sundaytimes.lk/231210/business-times/sling-mobility-usaid-collaborate-to-promote-clean-energy-541132.html',
    },
    {
      title: 'Uber operates Sling Mobility bikes',
      thumbnail: '/images/uber_press.jpg',
      content: 'Uber, one of Sri Lanka’s leading ridesharing apps, has announced a pilot with Sling Mobility, a global provider of platform as a service (PaaS) for building a smart EV ecosystem.As part of this pilot, Uber will onboard an electric two-wheeler fleet that is run and operated by Sling Mobility on its platform. Sling will also offer easy financial solutions including a unique battery subscription model to reduce the EV adoption cost for existing and new driver partners on the Uber platform.',
      url: 'https://archives1.dailynews.lk/2023/03/01/business/298379/uber-operates-sling-mobility-bikes',
    },
    {
      title: 'Sling Mobility roped in as Official Partner for Lanka Premier League 2023',
      thumbnail: '/images/lpl.jpeg',
      content: 'Sling Mobility, one of Sri Lanka’s fastest-growing EV two-wheeler and three-wheeler ecosystems, was roped in as Official Partner for the forthcoming Lanka Premier League.The league which is in its fourth year is all set to witness a galaxy of global cricket',
      url: 'https://www.ft.lk/sports/Sling-Mobility-roped-in-as-Official-Partner-for-Lanka-Premier-League-2023/23-751094#:~:text=Sling%20Mobility%2C%20one%20of%20Sri,fighting%20for%20the%20coveted%20title.',
    },
    {
      title: 'USAID/Sri Lanka Helps Power Up Trial of E-battery Swapping Stations in Colombo',
      thumbnail: '/images/slingusaid.jpg',
      content: 'The United States Agency for International Development (USAID) Sri Lanka Energy Program and Sling Mobility (Pvt.) Ltd.',
      url: 'https://www.usaid.gov/sri-lanka/press-releases/apr-18-2023-usaidsri-lanka-helps-power-trial-e-battery-swapping-stations-colombo',
    },
    {
      title: 'Sling Mobility, USAID collaborate to propel sustainable e-mobility, clean energy',
      thumbnail: '/images/image.jpeg',
      content: 'In a significant stride towards advancing e-mobility and clean energy initiatives, Sling Mobility, in collaboration with the United States Agency for International Development (USAID) Sri Lanka Energy Program, has successfully launched the “360° Sustainable Mobility” battery swapping project. ',
      url: 'https://www.ft.lk/business/Sling-Mobility-USAID-collaborate-to-propel-sustainable-e-mobility-clean-energy/34-755951',
    },
    {
      title: 'SLING Mobility and USAID Collaborate to Propel Sustainable E-Mobility and Clean Energy in Sri Lanka',
      thumbnail: '/images/image.jpeg',
      content: 'SLING Mobility and the USAID Sri Lanka Energy Program are committed to engaging with marginalized and economically disadvantaged populations, aiming to stimulate their interest in the retail logistics industry through the use of electric vehicles. The project organized and executed four outreach initiatives, reaching over 100 participants from diverse backgrounds.  ',
      url: 'https://lankafirst.co/sling-mobility-and-usaid-collaborate-to-propel-sustainable-e-mobility-and-clean-energy-in-sri-lanka/',
    },
    {
      title: 'SLING Mobility and USAID Collaborate to Propel Sustainable E-Mobility and Clean Energy in Sri Lanka',
      thumbnail: '/images/press_batteryswap.jpeg',
      content: 'SLING Mobility s collaboration with USAID marks a significant step towards a sustainable and green future for urban mobility in Sri Lanka. The 360° Sustainable Mobility battery swapping project is not just a technological innovation but a testament to the transformative power of partnerships driving positive change.',
      url: 'https://lankafirst.co/sling-mobility-and-usaid-collaborate-to-propel-sustainable-e-mobility-and-clean-energy-in-sri-lanka/',
    },
    {
      title: 'SLING MOBILITY AND USAID',
      thumbnail: '/images/image.jpeg',
      content: 'This groundbreaking project will establish an operational framework for an electric vehicle (EV) ecosystem, with an emphasis on utilizing solar power as a main energy source. The pilot project introduces two solar-powered battery-swapping stations strategically located at CENWOR and Fusion Sarvodaya in Colombo. The stations have the capacity to recharge and store batteries, ensuring a seamless and sustainable electric two-wheeler refueling model.',
      url: 'https://lmd.lk/sling-mobility-and-usaid/',
    },
    
  ];

  return (
    <div className="container text-center my-5">
      <h1 className="pt-5 mb-5 page-title">Press</h1>
      <div className="members-grid mb-5">
        {newsArticles.map((article, index) => (
          <div className="card " key={index}>
            <img
              src={article.thumbnail}
              alt={article.title}
              className="article-thumbnail img-fluid" 
            />
            <h4 className='mb-4'>{article.title}</h4>
            <p>{article.content}</p>
            <PrimaryButton onClick={() => window.open(article.url, '_blank')}>
            Read More
            </PrimaryButton>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Press;
