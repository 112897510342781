import { Link, NavLink } from 'react-router-dom'
import './NavBar.css'

function Navbar() {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src='/images/LogoWhite.png' className='img-fluid' alt='logo' />
          </Link>
          <button
            className="navbar-toggler py-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="nav navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink
                  className={({ isActive, isPending }) => isPending ? "pending nav-link" : isActive ? "active nav-link" : "nav-link"} to="/"
                >
                  <p className='my-0' data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                    Ecosystem
                  </p>
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a className="dropdown-toggle" href=" " data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Products
                </a>
                <div className="dropdown-menu">
                  <h3 className="dropdown-header">Bikes</h3>
                  <NavLink className="dropdown-item" to="products/ql8M726BthMRx4V4meCY">
                    <p className='my-0' data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                      Sling RS
                    </p>
                  </NavLink>
                  <NavLink className="dropdown-item" to="products/STKWdZp4KtwGtU4Yunpz">
                    <p className='my-0' data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                      Sling ES
                    </p>
                  </NavLink>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a className="dropdown-toggle" href=" " data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Subscriptions
                </a>
                <div className="dropdown-menu">
                  <NavLink className="dropdown-item" to="subscriptions">
                    <p className='my-0' data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                      Battery Subscription
                    </p>
                  </NavLink>
                  <NavLink className="dropdown-item" to="https://slingchain.slingmobility.com/">
                    <p className='my-0' data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                      Sling Chain
                    </p>
                  </NavLink>
                </div>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive, isPending }) => isPending ? "pending nav-link" : isActive ? "active nav-link" : "nav-link"}
                  to="/fleet"
                >
                   <p className='my-0' data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                    Fleet Management
                  </p>
                </NavLink>
              </li> 
              {/* <li className="nav-item">
                <NavLink
                  className={({ isActive, isPending }) => isPending ? "pending nav-link" : isActive ? "active nav-link" : "nav-link"}
                  to="/buy"
                >
                  <p className='my-0' data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-expanded="false" aria-controls="navbarCollapse">
                    Buy
                  </p>
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </nav >
    </header >
  )
}

export default Navbar
