import React from 'react'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { ReactComponent as SettingsIcon } from './icons/settings.svg'
import { ReactComponent as AppIcon } from './icons/app.svg'
import { ReactComponent as BatteryIcon } from './icons/battery.svg'
import { ReactComponent as SwappingIcon } from './icons/swapping.svg'
import { ReactComponent as SubscriptionIcon } from './icons/subscription.svg'
import BoxLogo from './icons/BoxLogo.png'
import './Ecosystem.css'
import { Clear } from '@mui/icons-material'

function Ecosystem() {
  const ecosystem = [
    {
      image: <SwappingIcon />,
      text: 'Battery Swapping'
    },
    {
      image: <BatteryIcon />,
      text: 'High Performing Battery'
    },
    {
      image: <AppIcon />,
      text: 'Intelligent Mobile App'
    },
    {
      image: <SettingsIcon />,
      text: 'Cloud Connected'
    },
    {
      image: <SubscriptionIcon />,
      text: 'Battery Subscription'
    },
    {
      image: <SettingsIcon/>,
      text: 'Personalized'
    }
  ]

  return (
    <div>
      <SpeedDial
        ariaLabel="ecosystem"
        className='ecosystem-btn'
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        openIcon={<Clear />}
        icon={
          <SpeedDialIcon
            icon={
              <img src={BoxLogo} width={'50px'} alt='ecosystem'/>
            }
            openIcon={<Clear />}
          />
        }
      >
        {ecosystem.map((action: any, index: number) => (
          <SpeedDialAction
            tooltipOpen
            key={index}
            icon={action.image}
            tooltipTitle={action.text}
          />
        ))}
      </SpeedDial>
    </div>
  )
}

export default Ecosystem