import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import SelectableCard from "../../components/SelectableCard/SelectableCard";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/CustomMUI/CustomButtons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FirebaseServices from "../../Services/FirebaseServices";

function RideEarn(props: {
  isLoading: boolean;
  userId: string;
  saveData: CallableFunction;
}) {
  const firebase = new FirebaseServices();
  const [selectedPartner, setSelectedPartner] = useState("" as string);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const navigate = useNavigate();

  const partners = [
    {
      id: "Sling Mobility",
      name: " ",
      partnerLogo: "/images/LogoVertical.png",
    },
    {
      id: "Uber",
      name: " ",
      partnerLogo: "/images/Uber.jpg",
    },
    {
      id: "PickMe",
      name: " ",
      partnerLogo: "/images/PickMe.jpg",
    },
  ];

  async function handleProceed() {
    if (selectedPartner === "") {
      return toast.error("Please select a partner");
    }
    setIsButtonLoading(true);
    var x = await props.saveData();
    if (x===1) {
      await firebase
      .updateRideEarnPartner(selectedPartner, props.userId)
      .then((res: any) => {
        toast.success(res as string);
        setIsButtonLoading(false);
        navigate("/");
      })
      .catch((error: any) => {
        toast.error(error as string);
        });
    }     

  }

  return (
    <div className="container mt-3">
      {props.isLoading ? (
        <div>
          <CircularProgress />
          <h5>Please wait till we save your data.</h5>
          <p>Please do not reload</p>
        </div>
      ) : (
        <div>
          <h5>Interested to make an extra income while riding your bike?</h5>
          <p>
            Choose a partner of your choice and we will help you get registered.
          </p>
          <div className="select-card-grid mb-4">
            {partners?.map((partner: any, index: any) => (
              <SelectableCard
                cardData={partner}
                selectedId={selectedPartner}
                key={index}
                onClick={setSelectedPartner}
              />
            ))}
            <SecondaryButton
              onClick={async () => {
                var x = await props.saveData();
                if (x===1) {navigate("/");}
              }}
            >
              Not Interested
            </SecondaryButton>
          </div>
          <PrimaryButton onClick={handleProceed}>
            Finish &nbsp;
            {isButtonLoading ? <CircularProgress color="inherit" /> : null}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}

export default RideEarn;
