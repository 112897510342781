import React, { useEffect, useState } from 'react'
import FirebaseServices from '../../Services/FirebaseServices';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import SelectableCard from '../../components/SelectableCard/SelectableCard';
import { PrimaryButton, SecondaryButton } from '../../components/CustomMUI/CustomButtons';

function Packages(props: { handleNext: CallableFunction, handleBack: CallableFunction }) {
  const firebase = new FirebaseServices();
  const [subscriptions, setSubscriptions] = useState([] as any)
  const [selectedSubscription, setSelectedSubscription] = useState("" as string)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getSubscriptions()

    if (localStorage.getItem("SelectedSubscription")) {
      setSelectedSubscription(localStorage.getItem("SelectedSubscription") as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getSubscriptions() {
    setIsLoading(true)
    await firebase.getSubscriptions().then((packageDetails: any) => {
      setSubscriptions(packageDetails)
      setIsLoading(false)
    }).catch((error) => {
      toast.error(error)
    });
  }

  function handleProceed() {
    if (selectedSubscription === "") {
      return toast.error("Please select the battery subscription package")
    }
    localStorage.setItem("SelectedSubscription", selectedSubscription)
    props.handleNext()
  }

  return (
    <div className='container mt-3'>
      {isLoading ?
        <CircularProgress />
        :
        <div>
          <div className='select-card-grid mb-5'>
            {subscriptions?.map((subscription: any, index: any) =>
              <SelectableCard
                cardData={subscription}
                selectedId={selectedSubscription}
                key={index}
                onClick={setSelectedSubscription}
              />
            )}
          </div>
          <div className='d-flex justify-content-start'>
            <PrimaryButton onClick={handleProceed} className='me-3'> Next </PrimaryButton>
            <SecondaryButton onClick={() => props.handleBack()}> Back </SecondaryButton>
          </div>
        </div>
      }
    </div>
  )
}

export default Packages