import React from 'react'
import { SecondaryButton } from '../../components/CustomMUI/CustomButtons'
import { useNavigate } from 'react-router-dom';

function HeroBikes(props: { products: any }) {
  const navigate = useNavigate();

  return (
    <div className='container p-5 text-center'>
      <h1 className='page-title'>Buy your favourite EV</h1>
      <p>Smart. Connected. Convenient</p>
      <div className='mt-5 home-bike-grid'>
        {props.products.map((product: any, index: number) =>
          <div
            key={index}
            className='hero-bike-card card'
            data-aos-anchor="hero-bike-card" data-aos='fade-up' data-aos-delay={index * 200}
          >
            <div className='row align-items-center'>
              <div className='col-xl-7'>
                <h1 className='text-blue fw-bolder mb-3'>Sling {product.model}</h1>
                <div className='image-container'>
                  <img src={product.images[1]} className='img-fluid' alt='product' />
                </div>
              </div>
              <div className='col-xl-5 text-center'>
                <div className='mb-3' data-aos="fade-up">
                  <h4 className='mb-0'>{product.power !== null ? `${product.power.toFixed(1)} kW` : "Coming Soon"}</h4>
                  <p>Motor power</p>
                </div>
                <div className='mb-3' data-aos="fade-up" data-aos-delay='100'>
                  <h4 className='mb-0'>{product.motorType !== null ? (product.motorType) : "Coming Soon"}</h4>
                  <p>Motor Type</p>
                </div>
                <div className='mb-3' data-aos="fade-up" data-aos-delay='200'>
                  <h4 className='mb-0'>{product.topSpeed !== null ? `${product.topSpeed} km/h` : "Coming Soon"}</h4>
                  <p>Max Speed</p>
                </div>
                <div className='mb-4' data-aos="fade-up" data-aos-delay='300'>
                  <h4 className='mb-0'>{product.range !== null ? `${product.range} km` : "Coming Soon"}</h4>
                  <p>Range per charge</p>
                </div>
                <SecondaryButton
                  data-aos="fade-up"
                  onClick={() => navigate(`/products/${product.id}`)}
                >
                  Read More
                </SecondaryButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HeroBikes