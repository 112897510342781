import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import './CustomButtons.css'

export function PrimaryButton(props: ButtonProps) {
  return (
    <Button
      onClick={props.onClick}
      disableElevation
      variant='contained'
      fullWidth={props.fullWidth}
      type={props.type}
      className={props.className + ' primary-btn' || 'primary-btn'}
    >
      {props.children}
    </Button>
  );
};

export function SecondaryButton(props: ButtonProps) {
  return (
    <Button
      onClick={props.onClick}
      disableElevation
      className={props.className + ' primary-hollow-btn' || 'primary-hollow-btn'}
      variant='outlined'
    >
      {props.children}
    </Button>
  );
};