import { CircularProgress } from '@mui/material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useState } from 'react';
import FirebaseServices from '../../Services/FirebaseServices';
import CustomTextField from '../CustomMUI/CustomTextField';
import { PrimaryButton } from '../CustomMUI/CustomButtons';
import { toast } from 'react-toastify';

function TestRide(props: { bikeId: any, onClose: CallableFunction; }) {
  const firebase = new FirebaseServices();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [date, setDate] = useState(null as any);
  const [time, setTime] = useState(null as any);
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setIsLoading(true)
    await firebase.newTestDrive(props.bikeId, name, email, contactNumber, date, time).then((res) => {
      setIsLoading(false)
      toast.success(res as String)
      props.onClose()
    }).catch((error) => {
      setIsLoading(false)
      toast.error(error.message)
    })
  };

  return (
    <div className='px-4 pb-4'>
      <form className='text-center' onSubmit={handleSubmit}>
        <CustomTextField
          label="Name"
          type='name'
          value={name}
          onChange={(e) => { setName(e.target.value) }}
        />
        <CustomTextField
          label="Email"
          type='email'
          value={email}
          onChange={(e) => { setEmail(e.target.value) }}
        />
        <CustomTextField
          label="Contact number"
          type='tel'
          value={contactNumber}
          onChange={(e) => { setContactNumber(e.target.value) }}
        />
        <div className='mt-4 row'>
          <div className='col-xl-6 mb-4'>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date"
                value={date}
                onChange={(newValue) => setDate(newValue)}
                slotProps={{
                  textField: {
                    required: true,
                    fullWidth: true
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          <div className='col-xl-6 mb-4'>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label="Time"
                value={time}
                onChange={(newValue) => setTime(newValue)}
                slotProps={{
                  textField: {
                    required: true,
                    fullWidth: true
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <PrimaryButton type='submit'>
          Book test ride &nbsp;
          {isLoading ? <CircularProgress color="inherit" /> : null}
        </PrimaryButton>
      </form>
    </div>
  )
}

export default TestRide