import React from 'react'
import { Link } from 'react-router-dom'

function PrivacyPolicy() {
  return (
    <div className='container mt-5 py-5 '>
      <div className='text-center mb-4'>
        <h2 className='page-title'>Privacy policy for SLING MOBILITY</h2>
        <p className='text-primary'>Last Updated 7th October 2022</p>
      </div>
      <p>
        At Sling Mobility, which is accessible from <Link to='https://slingmobility.com/'><b>https://slingmobility.com/</b></Link>, we always ensure that the information collected from you is protected at all costs. This privacy policy document that we present to you is relevant to this website and we mention the various information that is collected and all the relevant steps that are taken to safeguard them.
      </p>
      <p>
        If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
      </p>
      <p>
        By providing us information through our forums, you signify your acceptance of our Privacy Policy. Sling Mobility is the sole owner of this website including all the information that you submit through it.
      </p>
      <h3 className='mt-5'>What this privacy policy discloses</h3>
      <ul>
        <li>Information that we collect and how we manage them</li>
        <li>How do we collect your information?</li>
        <li>What do we do with your information?</li>
        <li>Third-party privacy policies</li>
        <li>GDPR Data Protection Rights</li>
        <li>Children's information</li>
        <li>Contact us</li>
      </ul>
      <h3 className='mt-5'>Information that we collect and how we manage them</h3>
      <p>
        All information that is collected depends on your actions within the website. Your personal details such as your name, email, and contact number are retrieved for any future purposes when we need to contact you. In addition to this, any other analytical information required to improve Sling Mobility will be collected.
      </p>
      <p>
        We do not retain information more than it is necessary. We only store all information only until it is needed for the specific purposes it was collected for. This retention period may vary from different situations and requirements. Once the information passes the specific retention period, it will be securely disposed of.
      </p>
      <p>
        We respect each and every one of your rights and privacy. You have the right to update your information and ensure that your personal data is accurate and up to date to avoid any misunderstandings. Furthermore, if any individual requests for their personal information to be removed we hereby follow the request as any website is bound by law to delete any user information if requested according to the GDPR (General Data Protection Regulation). By contacting Sling Mobility through <a href='mailto:info@slingmobility.com'>info@slingmobility.com</a> you have every right to request your information to be updated or removed.
      </p>
      <h3 className='mt-5'>How do we collect your information?</h3>
      <p>
        We collect personal information such as your Name, Email, and Contact Number which is collected through the Contact Form that is provided on the website.
      </p>
      <h3 className='mt-5'>What do we do with your information?</h3>
      <li>Log files</li>
      <p>
        The information collected for Analytics through the website is known as 'Log Files'. These files log user information when you visit Sling Mobility and track all actions done within the website. All hosting companies do this as a part of their hosting services' analytics.
      </p>
      <p>
        These log files include information such as demographic information of the user, the browser type, number of clicks, Internet Service Provider (ISP), and date with a timestamp of when the website was accessed. We follow a standard procedure when all this information is collected and it will only be used for the required purposes. The main purpose this information is collected is to analyze what the user does to improve the user experience of the website for future visitors.
      </p>
      <li>Analytics</li>
      <p>
        To measure Sling Mobility's Analytics we use Google Analytics. The log files that are collected are used by these tools to give us an idea about how well the website is doing and what areas could improve for future visitors. Such information is valuable for Sling Mobility to make sure all our visitors have a seamless experience within the website. The information collected is always protected in a standardized manner as both the third-party integrations and Sling Mobility follow all policies to protect them.
      </p>
      <li>Cookies</li>
      <p>
        Cookies, also known as browser cookies, are small encrypted text files located in the browser directories. These files help web developers to navigate within a website efficiently to help perform certain functions. Sling Mobility does not use any cookies to get information from our users but as we use third-party integrations there can be instances where you would have to accept any cookies. Cookies are created when a user's browser loads a particular website and the user information is stored in these to identify regular visitors to provide a customized experience. In addition to this, cookies regulate how ads, widgets, and other elements function on a website.
      </p>
      <h3 className='mt-5'>Third-party privacy policies</h3>
      <p>
        Sling Mobility does not have any access or control over the cookies that are used by the third-party integrations. As we do not have any control, we kindly request you to refer to the respective policies of these third-party sources as it may include you about how their procedures take place. In addition to this, if needed, they too will provide instructions on how to opt-out from certain options.
      </p>
      <h3 className='mt-5'>GDPR - General Data Protection Regulation</h3>
      <p>
        These data protection regulations allow any individual to have easier access to the data that is collected and helps to manage their own information. According to these regulations, any user has the right to request to access, update and remove their personal information when needed. The full GDPR rights for individuals includes 7 regulations about the information that is collected,
      </p>
      <ul>
        <li>Must be processed lawfully, fairly, and in a transparent manner.</li>
        <li>Must be collected for only specified, explicit, and legitimate purposes.</li>
        <li>Must always be the adequate amount that is needed.</li>
        <li>Must be accurate and up to date.</li>
        <li>Must not be kept any longer than necessary.</li>
        <li>Must be processed in a manner that ensures the security of personal data.</li>
      </ul>
      <p>
        If you have a request to make to Sling Mobility regarding any of these regulations, please contact us at info@slingmobility.com We will respond to you within one month of clarification of your matter. More information about the GDPR Rights can be accessible through General Data Protection Regulation (GDPR) - Official Legal Text.
      </p>
      <h3 className='mt-5'>Children's information</h3>
      <p>
        One of the main concerns at Sling Mobility is protection for children while using the internet. While we encourage parents to acknowledge and guide children's activities on the internet, Sling Mobility do not knowingly collect any personally identifiable information from anyone under the age of 13. If we become aware that we have collected Personal Data from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers.
      </p>
      <p>
        As we need to rely on consent as a legal basis for processing your information, a child would require consent from a parent. Therefore we may require parent's consent before we collect and use children's information.
      </p>
      <h3 className='mt-5'>Contact us</h3>
      <p>
        If you have any questions about Sling Mobility's privacy policy, please do not hesitate to contact us through <a href='mailto:info@slingmobility.com'>info@slingmobility.com</a>. We are open to any suggestions that you have!
      </p>
      
      <h3 className='mt-5'>Refund/Return and Cancellation Policy</h3>

      <p>
        <strong>1. Eligibility for Refund/Return:</strong>
        We take pride in providing high-quality electric vehicle-based logistics solutions. If you are not entirely satisfied with your purchase, you may be eligible for a refund or return. Please contact our customer support team within [X] days of receiving the product or service.
      </p>

      <p>
        <strong>2. Return Process:</strong>
        To initiate a return, please contact our customer support team at <a href='mailto:info@slingmobility.com'>info@slingmobility.com</a> or <a href='tel:+94775648650'>077 564 8650</a> to obtain a Return Merchandise Authorization (RMA) number. Clearly mention the RMA number on the packaging when returning the product.
      </p>

      <p>
        <strong>3. Conditions for Refund/Return:</strong>
        <ul>
          <li>Products/services must be in their original condition, undamaged, and with all components and accessories included.</li>
          <li>Return shipping costs are the responsibility of the customer unless the return is due to a defect or error on our part.</li>
          <li>We reserve the right to refuse returns that do not meet the above conditions.</li>
        </ul>
      </p>

      <p>
        <strong>4. Refund Processing:</strong>
        Upon receiving the returned item, we will inspect it and notify you of the status of your refund. If the return is approved, the refund will be processed, and a credit will automatically be applied to your original payment method within [X] days.
      </p>

      <p>
        <strong>5. Non-Refundable Items:</strong>
        Certain items, such as software licenses, may be non-refundable. Please check the product/service details for any specific non-refundable terms.
      </p>

      <p>
        <strong>6. Cancellation Policy:</strong>
        <ul>
          <li><strong>2.1 Order Cancellation:</strong> You may cancel your order within [X] hours of placing it. Contact our customer support team at <a href='mailto:info@slingmobility.com'>info@slingmobility.com</a> or <a href='tel:+94775648650'>077 564 8650</a> to request order cancellation.</li>
          <li><strong>2.2 Cancellation After Service Activation:</strong> If the service has been activated, and you wish to cancel, a prorated refund may be offered based on the remaining unused service period.</li>
          <li><strong>2.3 Refund for Canceled Orders:</strong> If your order is successfully canceled within the specified time frame, a refund will be processed to your original payment method within [X] days.</li>
        </ul>
      </p>

      <p>
        <strong>7. Contact Information:</strong>
        If you have any questions or concerns regarding our refund/return or cancellation policy, please contact us at <a href='mailto:info@slingmobility.com'>info@slingmobility.com</a> or <a href='tel:+94775648650'>077 564 8650</a>.
      </p>

      <p>
        We reserve the right to update or modify this policy at any time without prior notice.
      </p>
    <h3 className='mt-5'>Delivery Process:</h3>

      <ul>
        <li><strong>Shipping Methods:</strong> We offer standard shipping and expedited shipping options. The available shipping methods may vary based on your location.</li>

        <li><strong>Delivery Time:</strong> Delivery times are estimated based on the shipping method selected and your delivery address. Delays may occur due to unforeseen circumstances such as weather conditions or other external factors.</li>

        <li><strong>Order Tracking:</strong> Once your order is shipped, you will receive a confirmation email with tracking information. You can track your order's status using the provided tracking details on our website or through the designated courier service.</li>
      </ul>

      <h3 className='mt-5'>Delivery Restrictions:</h3>

      <ul>
        <li><strong>Shipping Locations:</strong> We currently offer delivery services to SriLanka. If your location is not covered by our standard delivery, please contact our customer support team to inquire about alternative shipping options.</li>

        <li><strong>P.O. Box Addresses:</strong> We do not deliver to P.O. Box addresses. Please provide a physical address for delivery.</li>

        <li><strong>Delivery Attempts:</strong> Our courier will attempt to deliver your package multiple times. If unsuccessful, they may leave a delivery notice for further instructions.</li>
      </ul>

      <h3 className='mt-5'>Special Conditions:</h3>

      <ul>
        <li><strong>Signature Requirement:</strong> Some high-value or sensitive orders may require a signature upon delivery for security purposes.</li>

        <li><strong>Large or Heavy Items:</strong> Additional charges may apply for the delivery of large or heavy items. Please check the product description for any specific delivery requirements.</li>

        <li><strong>Contactless Delivery:</strong> In adherence to safety measures, our delivery personnel may implement contactless delivery, leaving the package at your doorstep and notifying you of its arrival.</li>
      </ul>

      <h3 className='mt-5'>Delivery Support:</h3>

      <ul>
        <li><strong>Customer Support:</strong> If you have any questions or concerns regarding your delivery, please contact our customer support team at <a href='mailto:info@slingmobility.com'>info@slingmobility.com</a> or <a href='tel:+94775648650'>077 564 8650</a>.</li>

        <li><strong>Delivery Issues:</strong> In the event of any issues with your delivery, such as delays or damages, please notify our customer support team immediately for assistance.</li>
      </ul>

      <h3 className='mt-5'>Policy Updates:</h3>

      <ul>
        <li><strong>Changes to Delivery Policy:</strong> We reserve the right to update or modify this Delivery Policy at any time without prior notice.</li>
      </ul>
      <h3 className='mt-5'>Delivery Process:</h3>

      <ul>
        <li><strong>Shipping Methods:</strong> We offer standard shipping and expedited shipping options. The available shipping methods may vary based on your location.</li>

        <li><strong>Delivery Time:</strong> Delivery times are estimated based on the shipping method selected and your delivery address. Delays may occur due to unforeseen circumstances such as weather conditions or other external factors.</li>

        <li><strong>Order Tracking:</strong> Once your order is shipped, you will receive a confirmation email with tracking information. You can track your order's status using the provided tracking details on our website or through the designated courier service.</li>
      </ul>

      <h3 className='mt-5'>Special Conditions:</h3>

      <ul>
        <li><strong>Signature Requirement:</strong> Some high-value or sensitive orders may require a signature upon delivery for security purposes.</li>

        <li><strong>Large or Heavy Items:</strong> Additional charges may apply for the delivery of large or heavy items. Please check the product description for any specific delivery requirements.</li>

        <li><strong>Contactless Delivery:</strong> In adherence to safety measures, our delivery personnel may implement contactless delivery, leaving the package at your doorstep and notifying you of its arrival.</li>
      </ul>

      <h3 className='mt-5'>Delivery Charges:</h3>

      <ul>
        <li><strong>Standard Delivery:</strong> Standard delivery charges are applied based on the total order value and shipping destination.</li>

        <li><strong>Expedited Delivery:</strong> Expedited delivery options are available at an additional cost. The exact charges will be displayed during the checkout process.</li>
      </ul>

      <h3 className='mt-5'>Policy Updates:</h3>

      <ul>
        <li><strong>Changes to Delivery Policy:</strong> We reserve the right to update or modify this Delivery Policy at any time without prior notice.</li>
      </ul>
    </div>
  );
}


    

export default PrivacyPolicy