import React, { useState } from 'react';
import { LinkedIn } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import './Aboutus.css';
import { PrimaryButton } from '../../components/CustomMUI/CustomButtons';

// Define a type for a team member
interface TeamMember {
  name: string;
  role?: string;
  imageSrc: string;
  linkedinUrl?: string;
  description?: React.ReactNode;
}
const AboutUs = () => {
  const [selectedMember, setSelectedMember] = useState<TeamMember | null>(null);

  // Define constant arrays for co-founders and advisory board members
  const coFounders: TeamMember[] = [
    {
      name: 'Love Yadav',
      //role: 'Co-Founder',
      imageSrc: '/images/members/love-2.jpg',
      linkedinUrl: 'https://www.linkedin.com/in/love-yadav-00ab345/',
      description: 'Love is the General Partner of Lion Ventures with 20 plus years of global investment banking & asset management experience. He has lived and worked extensively in most international financial jurisdictions, including UAE, UK, Malaysia, Singapore, and held regulated positions with leadership roles. Love also served as a founding director and investment committee member for one of the largest India investment equity funds, which at its peak invested more than a Billion US$ into the Indian listed space. His longest stint had been with Reliance Capital group where he spent almost a decade. Over his career, he has advised on transactions, especially in China, Malaysia, Indonesia, Australia, Sri Lanka, India, Middle East & Africa in the Halal Sector, E Commerce, Technology, Financial Services, Telecommunications & Commodities space and built strong cross-border relations. Within the last five years, Love has been steadily building his venture build-up portfolio and structured the majority of his investments into a holding Company with Lion Ventures in Sri Lanka. His global investments span from Digital community management, Electric logistics, Digital wealth management, facilities management to the high growth medicinal marijuana sector.'
    },
    {
      name: 'Peeyush Asati',
      //role: 'Co-Founder',
      imageSrc: '/images/members/asati-1.jpg',
      linkedinUrl: 'https://www.linkedin.com/in/peeyush-asati-147aaa144/',
      description: 'Peeyush has a Masters Physics from IIT Kanpur and is the Co-Founder of Vecmocon Technologies, which is an award-winning start up on electric mobility. He has expertise in vehicle intelligence modules, LI Battery tech, Battery management systems and Cloud Architecture solutions.',
    },
    {
      name: 'Lion Ventures',
      //role: 'Co-Founder',
      imageSrc: '/images/members/lion-1.jpg',
      description: 'Visit Us On https://lionventures.cc/',
    },
  ];

  const advisoryBoard: TeamMember[] = [
    {
      name: 'Dr. Conny Boersch',
      imageSrc: '/images/members/conny-1.jpg',
      linkedinUrl: 'https://www.linkedin.com/in/connyboersch/',
      description: 'Dr. Cornelius Boersch (“Conny”) is the founder of Mountain Partners AG and has been a passionate entrepreneur, investor, and founder of numerous technology companies for the past 25 years. Cornelius Boersch founded his first company during his studies, developed it into the smart card broker and RFID-producer ACG AG, and successfully floated it on the Frankfurt Stock Exchange in 1999. Subsequently, Conny Boersch initiated the development of further RFID-based companies, such as Smartrac (IPO’d in 2006), Identiv (NASDAQ-listed since 2006), and recently the cashless payment systems provider Sandpiper Digital Payments. With the founding of MP, Conny dedicated himself to the development of internet and technology companies. He is the “European Business Angel of the Year 2009” and currently one of the most active and well-recognized Business Angels in Europe, whose investments are co-financed by the European Investment Fund. He studied at the European Business School in Oestrich-Winkel and earned his Ph.D. from the University of Duisburg in Essen.'
    },
    {
      name: 'Jorge Perez Garcia',
      imageSrc: '/images/members/jorge-1.jpg',
      linkedinUrl: 'https://www.linkedin.com/in/jorgeperezgarcia79/',
      description: 'Jorge Perez Garcia brings over 13 years of experience, having worked with three Fortune 500 companies, where he excelled in building cross-functional teams to achieve maximum operational impact. In his most recent role as Commercial Excellence & Execution Director Europe at Novartis, Jorge had the privilege of collaborating closely with Biotech companies, which ignited his passion for assisting start-ups in realizing their full potential. Joining Conny & Co. in June 2019, Jorge focuses on selecting, structuring, and negotiating investment opportunities, primarily in LATAM and Southern Europe. He also ensures the adequate follow-up with investors and founders. Jorge is a graduate of the executive program in Venture Finance at the University of Oxford, holds an Executive MBA from the University of Barcelona, and has participated in several programs in the areas of finance, coaching, and marketing excellence.'
    },
    {
      name: 'Asad Sultan',
      //role: 'Advisory Board Member',
      imageSrc: '/images/members/asad-1.jpg',
      linkedinUrl: 'https://www.linkedin.com/in/asad-sultan-06132112b/',
      description: 'Asad Sultan is the CEO of Deutsche Malayan Ventures and brings nearly 30 years of expertise as an equities and capital markets professional. With a rich history of holding C-level executive positions at renowned institutions like Macquarie Bank, ITG Securities, and Cantor Fitzgerald, Asad has developed a deep understanding of the financial landscape. He has also been a principal partner in entrepreneurial ventures in Malaysia, specifically in the solar and renewable energy sector, as well as in Hollywood, focusing on media and entertainment. Asads extensive networks span across key regions, including HK/China, Malaysia/SE Asia, India/S. Asia, Germany/Europe, and California/N. America.'
    },
    {
      name: 'Suraj Arora',
      imageSrc: '/images/members/suraj-1.jpg',
      linkedinUrl: 'https://www.linkedin.com/in/surajarora22/',
      description: 'Suraj Arora is an accomplished banker with 25 years of corporate experience, boasting expertise in various domains such as Mergers & Acquisitions, Fund Raising, Mentoring New Ventures, Start-up investments, Corporate & Investment Banking, Remittance, and Digital Banking. Currently serving as the Executive Director of Hatcher Malaysia, the SE Asia arm of the world’s largest AI-driven automated start-up venture fund, Suraj is also a Managing Partner in Village Groupe’s portfolio of entertainment-themed developments in India. With a 17-year tenure as the Country Head for ICICI Bank in Malaysia, one of Indias largest private sector banks with assets exceeding USD 150 Billion, Suraj played a pivotal role in establishing new ventures and business lines for the bank. He successfully secured substantial investments and funding for start-up projects through his extensive contacts in Institutional Funds and Private Equity. Moreover, Suraj initiated the remittance business of ICICI Bank in Malaysia, growing it from its inception to an annual turnover of 250 million USD. His diverse industry knowledge encompasses sectors such as Automobiles, Power, Retail, Media, Fintech, F&B, Food Products, and Renewable Energy. He has extensive networks across South East Asian markets, including Malaysia, Thailand, Philippines, and his home market of India. In addition, Suraj serves as the Secretary of the Consortium of Indian Industries in Malaysia, an organization representing Indian Industries in Malaysia, and has successfully organized ASEAN-India bilateral events. As an avid start-up investor, Suraj has made investments across media and technology platforms. He holds an MBA degree from Panjab University, Chandigarh, and is a Mechanical Engineer from Delhi College of Engineering, Delhi, India.'
    },
    {
      name: 'Nachi Das',
      imageSrc: '/images/members/nachi-1.jpg',
      linkedinUrl: 'https://www.linkedin.com/in/nachidas/',
      description: 'Nachi Das is a seasoned entrepreneur, investor, business leader, and former Wall Street executive with nearly 30 years of experience in capital markets spanning New York, Asia, and Europe. Residing in both Tokyo and New York, Nachi is actively involved in investing and developing ventures across various industries, including fintech, security/privacy, wellness/health, renewable energy, biotech, real estate, and hospitality and leisure. His ongoing ventures encompass pioneering Indias first digital neobank for the unbanked, a cybersecurity and privacy-focused enterprise, wellness ecosystems and projects in Japan, biotech initiatives, and disruptive digital health devices across Asia and the US. Nachi began his career by developing critical, real-time telecom technologies for Indias first digital telecom exchanges. Over the past 25 years, he has held global leadership positions in investment, financing, fintech, structuring, risk management, and portfolio management at notable institutions like Lehman Brothers, Barclays, State Street, and fintech startups in the US. He has been an early contributor and mentor to the thriving tech and venture ecosystem in New York. Nachi holds an MBA from the International University of Japan, a joint program with Dartmouth University in the USA, and London Business School. He also earned a Bachelor of Engineering degree in Electronics Engineering from NIT, Trichy, one of Indias top ten engineering institutes.'
    },
    {
      name: 'Ramisha Karunaratne',
      imageSrc: '/images/members/ramisha.jpg',
      linkedinUrl: 'https://www.linkedin.com/in/ramisha-karunaratne-484b3a152/',
      description: 'Ramisha Karunaratne boasts a remarkable career spanning over 25 years in the Financial Industry, with a strong focus on the Commercial Banking Sector. For two of Sri Lankas leading private banks, she has contributed her expertise across various critical domains, including General Banking, Corporate and Retail Lending, Credit Risk, Special Asset Management, and Recoveries. Her specialization lies in Risk, Compliance, and Security Documentation, with a dedicated focus in this area for the past 11 years.'
    },
    {
      name: 'Takaya Mitani',
      imageSrc: '/images/members/kyoya-desktop-popup.jpg',
      linkedinUrl: 'https://www.linkedin.com/in/takaya-mitani-452ba41a8/',
      description: 'Takaya Mitani embarked on his impressive career in 2001 when he joined The Bank of Tokyo-Mitsubishi, Ltd. (now MUFG Bank, Ltd.), where he served as a professional coordinator specializing in comprehensive asset preservation for high-net-worth individuals. This role was based in one of the few private banks in Japan, underlining his commitment to wealth management. Subsequently, he contributed his expertise at CITIBANK NA (now CITIBANK Bank, Ltd.) and Nomura Securities Co., Ltd. After his retirement, Takaya earned high praise from financial institutions for his profound knowledge, practical skills, and extensive global financial connections, which included interactions with the owners of various private banks. His unique career journey reflects his unwavering dedication to preserving the wealth of many of Japans most affluent individuals over the past two decades.'
    },
  ];

  // Function to open the member's description in a popup
  const handleOpenDescription = (member: TeamMember) => {
    setSelectedMember(member);
  };

  // Function to close the description popup
  const handleCloseDescription = () => {
    setSelectedMember(null);
  };

  return (
    <div className="container text-center my-5">
      <h1 className="pt-5 mb-5 page-title">Our Team</h1>
      <h3 className="text-start">The Co-Founders</h3>
      <div className="members-grid mb-5">
        {coFounders.map((member, index) => (
          <div className="card text-center align-items-center" key={index}>
            <img src={member.imageSrc} alt={member.name} className="member-img img-fluid" />
            <h5>{member.name}</h5>
            {member.role && <Typography variant="body2">{member.role}</Typography>}
            {member.linkedinUrl && (
              <a href={member.linkedinUrl} target="_blank" rel="noopener noreferrer"><LinkedIn /></a>
            )}
            {member.description && (
              <PrimaryButton onClick={() => handleOpenDescription(member)}>
                Read More
              </PrimaryButton>
            )}
          </div>
        ))}
      </div>
      <h3 className="text-start">The Advisory Board</h3>
      <div className="members-grid">
        {advisoryBoard.map((member, index) => (
          <div className="card text-center align-items-center" key={index}>
            <img src={member.imageSrc} alt={member.name} className="member-img img-fluid" />
            <h5>{member.name}</h5>
            {member.role && <Typography variant="body2">{member.role}</Typography>}
            {member.linkedinUrl && (
              <a href={member.linkedinUrl} target="_blank" rel="noopener noreferrer">
                <LinkedIn />
              </a>
            )}
            {member.description && (
              <PrimaryButton
                onClick={() => handleOpenDescription(member)}
              >
                Read More
              </PrimaryButton>
            )}
          </div>
        ))}
      </div>
      {/* Render the description popup */}
      <Dialog
        open={Boolean(selectedMember)}
        onClose={handleCloseDescription}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          {selectedMember && (
            <div>
              <Typography variant="h5">{selectedMember.name}</Typography>
              {selectedMember.role && <Typography variant="body2">{selectedMember.role}</Typography>}
              <Typography variant="body1">{selectedMember.description}</Typography>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AboutUs;
