import firebase from "firebase/compat/app";
import "firebase/compat/auth"; // for authentication
import "firebase/compat/firestore"; // for cloud firestore
import "firebase/compat/analytics"; // for analytics
import "firebase/compat/storage"; // for analytics
import moment from "moment";

export default class FirebaseServices {
  constructor() {
    if (!firebase.apps.length) {
      this.initFirebase();
    }
  }

  getRefinedFirebaseErrorMessage(errorMessage: string) {
    return errorMessage.replace("Firebase: ", "").replace(/\(.*\)\.?/, "");
  }

  initFirebase() {
    const firebaseConfig = {
      apiKey: "AIzaSyBEN3TQXXkswqbPpYZg_tVK0ORIN5z0Dio",
      authDomain: "sling-mobility-d5949.firebaseapp.com",
      databaseURL: "https://sling-mobility-d5949-default-rtdb.firebaseio.com",
      projectId: "sling-mobility-d5949",
      storageBucket: "sling-mobility-d5949.appspot.com",
      messagingSenderId: "779962716575",
      appId: "1:779962716575:web:354e150763fcf476976daa",
      measurementId: "G-XX59D4CV3D",
    };
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }

  newTestDrive(
    bikeId: String,
    name: String,
    email: String,
    contactNumber: String,
    date: any,
    time: any
  ) {
    const data = {
      name: name,
      email: email,
      contactNumber: contactNumber,
      date: moment(date).format("DD MMM YYYY"),
      time: moment(time).format("hh:mm A"),
      bike: bikeId,
      approved: false,
      status: "pending",
    };

    return new Promise(async (resolve, reject) => {
      await firebase
        .app()
        .firestore()
        .collection("test_drives")
        .add(data)
        .then(() => {
          resolve("Test drive request sent successfully");
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async getBikeById(bikeId: string) {
    return new Promise((resolve, reject) => {
      firebase
        .app()
        .firestore()
        .collection("bikes")
        .doc(bikeId)
        .get()
        .then((result) => {
          let bike = result.data() as any;
          bike.id = result.id;
          resolve(bike);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async getSubscriptions() {
    return new Promise((resolve, reject) => {
      firebase
        .app()
        .firestore()
        .collection("subscription_packages")
        .orderBy("range")
        .get()
        .then((result) => {
          let subscriptions = [] as any[];
          result.docs.forEach((doc) => {
            let subscription = doc.data() as any;
            subscription.id = doc.id;
            subscriptions.push(subscription);
          });

          resolve(subscriptions);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async getAllBikes() {
    return new Promise((resolve, reject) => {
      firebase
        .app()
        .firestore()
        .collection("bikes")
        .get()
        .then((result) => {
          let bikes = [] as any[];
          result.docs.forEach((doc) => {
            let bikeData = doc.data() as any;
            bikeData.id = doc.id;
            bikes.push(bikeData);
          });
          let sortedBikes = bikes.sort((a, b) =>
            b.model.localeCompare(a.model)
          );
          resolve(sortedBikes);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async getAllLeasingAgents() {
    return new Promise((resolve, reject) => {
      firebase
        .app()
        .firestore()
        .collection("leasing_agents")
        .get()
        .then((result) => {
          let agents = [] as any[];
          result.docs.forEach((doc) => {
            let agentData = doc.data() as any;
            agentData.id = doc.id;
            agents.push(agentData);
          });
          resolve(agents);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  uploadFile(uid: string, dataString: string, imageType: string) {
    let storageRef: firebase.storage.Reference;

    if (imageType === "payment") {
      storageRef = firebase
        .storage()
        .ref("/slingmobility.com/PaymentSlips/")
        .child(uid);
    } else {
      storageRef = firebase
        .storage()
        .ref("/slingmobility.com/NIC/")
        .child(`${uid}_${imageType}`);
    }

    return new Promise(async (resolve, reject) => {
      await storageRef
        .putString(dataString, "data_url")
        .then(async (snapshot) => {
          resolve(await snapshot.ref.getDownloadURL());
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  newUser(customerDetails: any) {
    const data = {
      firstname: customerDetails.firstname,
      lastname: customerDetails.lastname,
      name: customerDetails.firstname + " " + customerDetails.lastname, //fleet needs this field
      email: customerDetails.email,
      contactNumber: customerDetails.contactNumber,
      nic: customerDetails.nic,
      selectedBike: customerDetails.selectedBike,
      selectedSubscription: customerDetails.selectedSubscription,
      userRole: "floatingRider",
      logins: 0, // mobile app tutorial
    };

    return new Promise(async (resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, "12345678")
        .then((res: any) => {
          let userId = res.user.uid;
          firebase
            .app()
            .firestore()
            .collection("users")
            .doc(userId)
            .set(data)
            .then(() => {
              resolve({ message: "User account created", uid: userId });
            })
            .catch((err) => {
              reject("Failed to save user data");
            });
        })
        .catch((error) => {
          reject(this.getRefinedFirebaseErrorMessage(error.message));
        });
    });
  }

  newPayment(userId: string, paymentDetails: any) {
    paymentDetails.userId = userId;
    paymentDetails.createdAt = firebase.firestore.FieldValue.serverTimestamp();

    return new Promise(async (resolve, reject) => {
      firebase
        .app()
        .firestore()
        .collection("payments")
        .add(paymentDetails)
        .then((res) => {
          const data = { paymentId: res.id };
          firebase
            .app()
            .firestore()
            .collection("users")
            .doc(userId)
            .update(data)
            .then(() => {
              resolve("Successfully saved the payment Details");
            })
            .catch((error) => {
              reject("Failed to save payment data");
            });
        })
        .catch((err) => {
          reject("Failed to save payment data");
        });
    });
  }
  updatePayment(userId: string, paymentDetails: any) {
    paymentDetails.userId = userId;
    paymentDetails.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    return new Promise(async (resolve, reject) => {
      firebase
        .app()
        .firestore()
        .collection("payments")
        .doc(paymentDetails.paymentDocId)
        .update(paymentDetails)
        .then((res) => {
          const data = { paymentId: paymentDetails.paymentDocId };
          firebase
            .app()
            .firestore()
            .collection("users")
            .doc(userId)
            .update(data)
            .then(() => {
              resolve("Successfully saved the payment Details");
            })
            .catch((error) => {
              reject("Failed to save payment data");
            });
        })
        .catch((err) => {
          reject("Failed to save payment data");
        });
    });
  }

  updateRideEarnPartner(partner: string, userId: string) {
    let data: any;

    if (partner === "Sling Mobility") {
      data = {
        fleetManager: partner,
        userRole: "rider",
        uid: userId,
        isActive: false,
        orderAssigned: false,
      };
    } else {
      data = {
        rideEarnPartner: partner,
      };
    }

    return new Promise(async (resolve, reject) => {
      firebase
        .app()
        .firestore()
        .collection("users")
        .doc(userId)
        .update(data)
        .then(() => {
          resolve(
            "Your details have shared with the organization. You will be contacted"
          );
        })
        .catch((err) => {
          reject("Failed initialise ride and earn process");
        });
    });
  }

  updateQRCount() {
    return new Promise(async (resolve, reject) => {
      firebase
        .app()
        .firestore()
        .collection("marketing")
        .doc("qr_count")
        .update({
          count: firebase.firestore.FieldValue.increment(1),
        })
        .then(() => {
          resolve("Updated QR count successfully");
        })
        .catch((err) => {
          reject("Failed to update QR count");
        });
    });
  }

  async getTotalCarbonEmissionSaved(): Promise<number> {
    return new Promise((resolve, reject) => {
      firebase
        .app()
        .firestore()
        .collection("CarbonData")
        .get()
        .then((result) => {
          let totalSaved = 0;
          result.docs.forEach((doc) => {
            const docData = doc.data() as any;
            console.log("Fetched docData:", docData);
            const carbonSaved = docData.totalCarbonEmissionSaved;
            if (carbonSaved !== null && typeof carbonSaved === "number") {
              totalSaved += carbonSaved;
            } else {
              console.warn("Invalid or null carbonSaved value:", carbonSaved);
            }
          });
          console.log("Total carbon emission saved:", totalSaved);
          resolve(totalSaved);
        })
        .catch((err) => {
          console.error("Error fetching data:", err);
          reject(err);
        });
    });
  }
}
