import React, { ReactNode, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Bike from "./pages/Bike/Bike";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import Home from "./pages/Home/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Buy from "./pages/Buy/Buy"; // Uncommented the Buy import
import Contact from "./pages/Contact";
import Press from "./pages/Press/Press";
import AboutUs from "./pages/Aboutus/Aboutus";
import AOS from "aos";
import "aos/dist/aos.css";
import Gallery from "./pages/Gallery/Gallery";
import FleetManagement from "./pages/FleetManagement/FleetManagement";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PaymentReturn from "./pages/PaymentReturn";
import CarbonQr from "./pages/CarbonQr/carbonQr";


function BuyWithParams() {
  const { initialStep } = useParams();
  return <Buy initialStep={Number(initialStep)} />;
}

interface LayoutProps {
  children: ReactNode;
}

function Layout({ children }: LayoutProps) {
  const location = useLocation();

  // Pages that should not have a Navbar or Footer
  const noHeaderFooterRoutes = ["/marketingQr"];

  return (
    <>
      {!noHeaderFooterRoutes.includes(location.pathname) && <Navbar />}
      {children}
      {!noHeaderFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  );
}

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  // Move AOS.init to useEffect to prevent multiple calls on rerenders
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <Router>
          <Layout>
            <ToastContainer theme="colored" />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products/:id" element={<Bike />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/buy" element={<Buy />} />
              <Route path="/buy/:initialStep" element={<BuyWithParams />} />
              <Route path="/fleet" element={<FleetManagement />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/press" element={<Press />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/payment-return" element={<PaymentReturn />} />
              <Route path="/marketingQr" element={<CarbonQr />} />
              
            </Routes>
          </Layout>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
